<!--
 * @Author: ChenYaJin
 * @Date: 2023-07-04 09:02:55
 * @LastEditors: LiZhiWei
 * @LastEditTime: 2024-11-27 09:52:17
 * @Description: 登录组件
-->
<template>
  <el-dialog :model-value="open" width="25%" close-icon="" :footer="null" @close="onCloseLogin">
    <template #header>
      <span class="font-s-20px">{{ config.websiteTitle }}</span>
    </template>
    <el-form
      v-if="!(isRegister || props.isOpenRegister)"
      ref="formModelRef"
      v-loading="isLoading"
      autocomplete="off"
      :model="formModel"
      :rules="formRules"
      size="large"
      class="py-1rem px-0.5rem"
      @keyup.enter="onLogin"
    >
      <el-form-item prop="username">
        <el-input
          v-model="formModel.username"
          name="username"
          placeholder="请输入手机号"
          :autofocus="true"
          autocomplete="off"
        >
          <template #prefix>
            <i i-ep-user></i>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          v-model="formModel.password"
          placeholder="请输入密码"
          :type="isEye"
          name="password"
          autocomplete="password"
        >
          <template #prefix>
            <i i-ep-lock></i>
          </template>
          <template #suffix>
            <i v-show="!isView" i-ep-view @click="onClickView"></i>
            <i v-show="isView" i-ep-hide @click="onClickView"></i>
          </template>
        </el-input>
      </el-form-item>
      <div class="mt-40px mb-16px operate-row">
        <span class="primary-item cursor-pointer" @click="openRegister">注册账号</span>
        <el-popover placement="bottom" :width="200" trigger="click">
          <span>{{ forgetMessage }}</span>
          <template #reference>
            <span class="warning-item cursor-pointer">忘记密码</span>
          </template>
        </el-popover>
      </div>
      <el-button
        type="primary"
        size="large"
        class="w-full mb-2"
        :loading="loading"
        @click="onLogin"
      >
        登录
      </el-button>
    </el-form>
    <RegisterModal
      v-else
      :is-login-to-register="isLoginToRegister"
      @close-login="onCloseLogin"
      @active-login="activeLogin"
    />
  </el-dialog>
</template>

<script setup lang="ts">
  import { reactive, ref } from 'vue'
  import { ElMessageBox } from 'element-plus'
  import type { FormRules } from 'element-plus'
  import { useLoginStore } from '@/stores/modules/user/login'
  import { useUserStore } from '@/stores/modules/user/user'
  import RegisterModal from './register.vue'
  import { encryptByMd5, getCanvasFingerprint } from '@/utils'
  import { AuditStatusEnum } from '@/models/enums'
  import { sendMessage } from '@/utils'
  import config from '@/constants'

  defineOptions({
    name: 'Login',
  })

  const props = defineProps<{
    open: boolean
    isOpenRegister: boolean
  }>()
  const emits = defineEmits(['closeLogin', 'closeRegister'])
  const useLogin = useLoginStore()
  const userStore = useUserStore()
  const isRegister = ref(false)
  const isLoginToRegister = ref(false)
  const isLoading = ref(false)
  const isView = ref(false)
  const isEye = ref('password')
  const formModelRef = ref<HTMLFormElement>()
  const formModel = reactive({
    username: '',
    password: '',
  })
  //   const sendData = ref({
  //     username: '',
  //     password: '',
  //   })
  const loading = ref(false)
  const formRules = reactive<FormRules>({
    username: [{ required: true, message: '请输入有效账号' }],
    password: [{ required: true, message: '请输入有效密码' }],
  })
  const forgetMessage = ref(
    '说明：企业联络人使用【手机号】及【密码】进行账号登陆与企业数据维护，需要修改时可进行修改企业联络人调整，若因其他原因导致登陆账号及密码丢失，可通过邮件、线下等方式联系物业协会，提供企业信息并重置账号。'
  )

  const activeLogin = () => {
    emits('closeRegister')
    isRegister.value = false
  }
  const onClickView = () => {
    isView.value = !isView.value
    isEye.value = isView.value ? 'text' : 'password'
  }
  const onLogin = () => {
    formModelRef?.value?.validate(async (valid: boolean) => {
      if (valid) {
        loading.value = true
        const params = {
          username: formModel.username,
          password: encryptByMd5(formModel.password),
          clientId: getCanvasFingerprint(),
          userType: 'ENTERPRISE',
        }
        useLogin
          .login(params)
          .then((res) => {
            if (res) {
              //   sendData.value = JSON.parse(JSON.stringify(formModel))
              getUserInfo()
              formModelRef.value?.resetFields()
            }
          })
          .finally(() => {
            loading.value = false
          })
      }
    })
  }
  const getUserInfo = async () => {
    const userInfo = await userStore.getUserEnterpriseInfo()
    emits('closeLogin')
    // 获取企业信息 根据状态跳转
    // 无企业信息： 提示，跳转用户页面-编辑
    // 审核中：跳转用户页面-预览
    // 审核失败：跳转用户页面-编辑
    // 审核通过: 跳转企业详情页
    if (!userInfo || !userInfo.id) {
      ElMessageBox.confirm(
        '<strong>您尚未提交企业基本情况，请前往填写并提交企业基本情况完成企业注册！</strong>',
        '',
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '填写',
          cancelButtonText: '退出登录',
          closeOnClickModal: false,
          confirmButtonClass: 'el-button el-button--primary',
          cancelButtonClass: 'el-button el-button--danger is-plain',
        }
      )
        .then(() => {
          toUserPage('edit')
        })
        .catch(() => {
          useLogin.logout()
        })
      return
    }
    const { auditStatus = '' } = userInfo
    if (auditStatus === AuditStatusEnum.APPROVED) {
      toEnterPage()
    } else {
      const status = ['review'].includes(auditStatus) ? 'view' : 'edit'
      toUserPage(status)
    }
  }
  /**
   * 根据状态跳转 用户信息页
   * @param status 审核状态
   */
  const toUserPage = async (status: string) => {
    emits('closeLogin')
    sendUserData()
  }

  const toEnterPage = async () => {
    sendMessage(
      import.meta.env.VITE_ADMIN_URL,
      `${import.meta.env.VITE_ADMIN_URL}/search?type=user`,
      {
        token: useLogin.getToken,
        refreshToken: useLogin.getRefreshToken,
      }
    )
  }
  /**
   * 跳转企业详情页
   */
  //   const toEnterpriseDetailPage = (id: string) => {
  //     emits('closeLogin')
  //     router.push({
  //       path: `/enterprise/${id}`,
  //     })
  //   }
  const openRegister = () => {
    isRegister.value = true
    isLoginToRegister.value = true
  }
  const onCloseLogin = () => {
    isRegister.value = false
    isLoginToRegister.value = false
    emits('closeLogin')
  }
  const sendUserData = () => {
    sendMessage(
      import.meta.env.VITE_ADMIN_URL,
      `${import.meta.env.VITE_ADMIN_URL}/search?type=open`,
      {
        token: useLogin.getToken,
        refreshToken: useLogin.getRefreshToken,
      }
    )
  }
</script>

<style scoped lang="scss">
  .input-hidden {
    position: absolute;
    z-index: -1;
    border: 0;
    height: 0;
    width: 0;
  }
  .operate-row {
    display: flex;
    justify-content: space-between;
    .warning-item {
      color: #ff495e;
    }
    .primary-item {
      color: #0f67f8;
    }
  }
</style>
