<!--
 * @Author: ChenYaJin
 * @Date: 2023-07-04 09:02:55
 * @LastEditors: LiZhiWei
 * @LastEditTime: 2024-11-25 10:40:33
 * @Description: 注册组件
-->
<template>
  <div class="flex items-center">
    <template v-if="!isShowAccount">
      <i v-if="isLoginToRegister" class="cursor-pointer i-ep-arrow-left" @click="toLogin"></i>
      <div class="flex-1 font-500 text-center">注册</div>
    </template>
    <template v-else>
      <div class="flex-1 font-500">账号信息</div>
    </template>
  </div>
  <el-form
    ref="formModelRef"
    v-loading="isLoading"
    autocomplete="off"
    :model="formModel"
    :rules="formRules"
    size="large"
    :validate-on-rule-change="false"
    class="py-1rem px-0.5rem"
    @keyup.enter="onRegister"
  >
    <el-form-item prop="name">
      <el-input
        v-model="formModel.name"
        name="name"
        placeholder="请输入姓名"
        :autofocus="true"
        autocomplete="off"
        :disabled="!isEditStatus"
      >
        <template #prefix>
          <i i-ep-user></i>
        </template>
      </el-input>
    </el-form-item>
    <el-form-item prop="email">
      <el-input
        v-model="formModel.email"
        name="email"
        placeholder="请输入邮箱地址"
        :autofocus="true"
        autocomplete="off"
        :disabled="!isEditStatus"
      >
        <template #prefix>
          <i i-ep-chat-dot-square></i>
        </template>
      </el-input>
    </el-form-item>
    <el-form-item prop="mobile">
      <el-input
        v-model="formModel.mobile"
        name="mobile"
        placeholder="请输入手机号"
        :autofocus="true"
        autocomplete="off"
        :disabled="!isEditStatus"
      >
        <template #prefix>
          <i i-ep-iphone></i>
        </template>
      </el-input>
    </el-form-item>
    <template v-if="isShowAccount && isEditStatus">
      <el-form-item prop="oldPassword">
        <el-input
          v-model="formModel.oldPassword"
          placeholder="请输旧入密码"
          :type="isOldEye"
          name="old-password"
          autocomplete="old-password"
          :disabled="!isEditStatus"
        >
          <template #prefix>
            <i i-ep-lock></i>
          </template>
          <template #suffix>
            <i v-show="!isOldView" i-ep-view @click="onOldClickView"></i>
            <i v-show="isOldView" i-ep-hide @click="onOldClickView"></i>
          </template>
        </el-input>
      </el-form-item>
    </template>
    <el-form-item prop="password">
      <el-input
        v-model="formModel.password"
        :placeholder="isShowAccount ? '请输入新密码' : '请输入密码'"
        :type="isEye"
        name="new-password"
        autocomplete="new-password"
        :disabled="!isEditStatus"
      >
        <template #prefix>
          <i i-ep-lock></i>
        </template>
        <template #suffix>
          <i v-show="!isView" i-ep-view @click="onClickView"></i>
          <i v-show="isView" i-ep-hide @click="onClickView"></i>
        </template>
      </el-input>
    </el-form-item>
    <template v-if="isShowAccount">
      <template v-if="isEditStatus">
        <el-button
          type="danger"
          plain
          round
          class="mt-2"
          :loading="loading"
          @click="onSetEditStatus(false)"
        >
          取消编辑
        </el-button>
        <el-button type="primary" round class="mt-2" :loading="loading" @click="onConfirmUpdate">
          确认编辑
        </el-button>
      </template>
      <template v-else>
        <el-button type="danger" plain round class="mt-2" @click="onLogout">退出登录</el-button>
        <el-button type="primary" round class="mt-2" @click="onSetEditStatus(true)">
          编辑账号
        </el-button>
      </template>
    </template>
    <template v-else>
      <el-button :loading="loading" type="primary" class="w-full mt-2" @click="onRegister">
        {{ loading ? '正在注册' : '确认注册' }}
      </el-button>
    </template>
  </el-form>
</template>

<script setup lang="ts">
  import { useRouter } from 'vue-router'
  import type { FormRules } from 'element-plus'
  import { encryptByMd5, getCanvasFingerprint, checkEmail, testMobile } from '@/utils'
  import { getUuIdSave } from '@/utils/auth'
  import type { IFormValidateCallback } from '@/models/common'
  import { useLoginStore } from '@/stores/modules/user/login'

  defineOptions({
    name: 'RegisterModal',
  })
  const props = defineProps({
    isLoginToRegister: {
      type: Boolean,
      default: false,
    },
    // 当前是否是修改账号页
    isShowAccount: {
      type: Boolean,
      default: false,
    },
  })
  const emits = defineEmits(['closeLogin', 'activeLogin', 'closeAccount'])
  const router = useRouter()
  const useLogin = useLoginStore()
  const isLoading = ref(false)
  const isView = ref(false)
  const isEye = ref('password')
  const formModelRef = ref<HTMLFormElement>()
  const formModel = ref({
    name: '',
    email: '',
    mobile: '',
    password: '',
    oldPassword: '',
  })
  // 修改账号信息相关 -start
  const isEditStatus = ref(true)
  const originFormModel = ref({})
  const getAccountDetail = () => {
    isEditStatus.value = false
    const uuid = getUuIdSave()
    if (uuid) {
      useLogin.getUserAccountInfo(uuid).then((res) => {
        if (res) {
          const { name, email, username } = res || {}
          formModel.value = {
            name: name || '',
            email: email || '',
            mobile: username || '',
            password: '123456',
            oldPassword: '',
          }
          originFormModel.value = JSON.parse(JSON.stringify(formModel.value))
        }
      })
    }
  }
  watch(
    () => props.isShowAccount,
    (newValue) => {
      if (newValue) {
        getAccountDetail()
      }
    },
    {
      immediate: true,
    }
  )
  const closeAccount = () => {
    emits('closeAccount')
  }
  const onSetEditStatus = (flag: boolean) => {
    isEditStatus.value = flag
    if (!flag) {
      formModel.value = JSON.parse(JSON.stringify(originFormModel.value))
      formModelRef?.value?.clearValidate()
    } else {
      formModel.value.password = ''
      formModel.value.oldPassword = ''
    }
  }
  const onLogout = () => {
    useLogin.logout().then(() => {
      closeAccount()
      router.replace({
        path: '/',
      })
    })
  }
  const isOldView = ref(false)
  const isOldEye = ref('password')
  const onOldClickView = () => {
    if (isEditStatus.value) {
      isOldView.value = !isOldView.value
      isOldEye.value = isOldView.value ? 'text' : 'password'
    }
  }
  const onConfirmUpdate = () => {
    formModelRef?.value?.validate(async (valid: boolean) => {
      if (valid) {
        loading.value = true
        const { name, email, mobile, password, oldPassword } = formModel.value
        const loginParams = {
          password: encryptByMd5(password),
          username: mobile,
          clientId: getCanvasFingerprint(),
          userType: 'ENTERPRISE',
        }
        const registerParams = {
          name,
          email,
          username: mobile,
          newPassword: encryptByMd5(password),
          oldPassword: encryptByMd5(oldPassword),
          clientId: getCanvasFingerprint(),
        }
        try {
          const registerResult = await useLogin.updateAccountInfo(registerParams)
          const loginResult = await useLogin.login(loginParams)
          if (registerResult && loginResult) {
            ElMessage.success('修改成功！')
            emits('closeAccount')
          }
        } finally {
          loading.value = false
        }
      }
    })
  }
  // 修改账号信息相关 -end
  const emailChecker = (_rule: unknown, value: string, callback: IFormValidateCallback) => {
    const isValid = checkEmail(value)
    if (!value) {
      callback(new Error('邮箱不能为空!'))
    }
    if (isValid) {
      callback()
    } else {
      callback(new Error('邮箱格式不正确！'))
    }
  }
  const phoneChecker = (_rule: unknown, value: string, callback: IFormValidateCallback) => {
    const isValid = testMobile(value)
    if (!value) {
      callback(new Error('手机号不能为空!'))
    }
    if (isValid) {
      callback()
    } else {
      callback(new Error('手机号格式不正确！'))
    }
  }
  const passwordChecker = (_rule: unknown, value: string, callback: IFormValidateCallback) => {
    if (!value) {
      const prefix = isEditStatus.value ? '新' : ''
      callback(new Error(`${prefix}密码不能为空!`))
    }
    if (value.length < 6 || value.length > 20) {
      callback(new Error('密码长度应为6-20位！'))
    } else {
      callback()
    }
  }
  const formRules = reactive<FormRules>({
    name: [{ required: true, message: '姓名不能为空', trigger: 'blur' }],
    email: [{ required: true, validator: emailChecker, trigger: 'blur' }],
    mobile: [{ required: true, validator: phoneChecker, trigger: 'blur' }],
    password: [{ required: true, validator: passwordChecker, trigger: 'blur' }],
    oldPassword: [{ required: true, message: '旧密码不能为空', trigger: 'blur' }],
  })
  const loading = ref(false)

  const toLogin = () => {
    emits('activeLogin')
  }
  const onClickView = () => {
    if (isEditStatus.value) {
      isView.value = !isView.value
      isEye.value = isView.value ? 'text' : 'password'
    }
  }
  /**
   * 注册-登录-获取企业信息
   */
  const onRegister = () => {
    formModelRef?.value?.validate(async (valid: boolean) => {
      if (valid) {
        loading.value = true
        const registerParams = {
          name: formModel.value.name,
          email: formModel.value.email,
          password: encryptByMd5(formModel.value.password),
          username: formModel.value.mobile,
          clientId: getCanvasFingerprint(),
        }
        useLogin
          .register(registerParams)
          .then((res) => {
            if (res) {
              ElMessage.success('注册成功，请登录并完善企业信息！')
              emits('activeLogin')
            }
          })
          .finally(() => {
            loading.value = false
          })
      }
    })
  }
</script>

<style scoped lang="scss"></style>
